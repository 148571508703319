<template>
    <div class="schedule-item-details-wrapper">
        <AppLoader
            v-if="educationalLevelsLoading"
            class="u-margin-bottom-small"
        />
        <div
            v-else-if="educationalLevelsLength > 0"
            class="schedule-item-details__section"
        >
            <AppRow v-if="educationalLevelsLength > 1" variant="bordered">
                <!-- eslint-disable-next-line -->
                <AppColumn v-for="(gradeLevels, levelGrade, index) in allEducationalLevels" :key="index">
                    <AppHeader>
                        <h2 v-translate class="subtitle title">
                            Onderwijsniveau
                        </h2>

                        <AppTitle
                            v-if="levelGrade"
                            as="h3"
                            size="xxsmall"
                            class="u-margin-top-small"
                        >
                            {{
                                $gettextInterpolate("Groep %{grade}", {
                                    grade: levelGrade
                                })
                            }}
                        </AppTitle>
                    </AppHeader>

                    <AppScheduleItemEducationalLevels
                        :grade-levels="gradeLevels"
                        :lower-grade="isLowerGrade"
                    />
                </AppColumn>
            </AppRow>
            <div v-else>
                <AppHeader>
                    <h2 v-translate class="subtitle title">
                        Onderwijsniveau
                    </h2>

                    <AppTitle
                        v-if="grade"
                        as="h3"
                        size="xxxsmall"
                        class="u-margin-top-small"
                    >
                        {{
                            $gettextInterpolate("Groep %{grade}", {
                                grade: grade
                            })
                        }}
                    </AppTitle>
                </AppHeader>

                <AppScheduleItemEducationalLevels
                    :grade-levels="Object.values(allEducationalLevels)[0]"
                    :lower-grade="isLowerGrade"
                />
            </div>
        </div>
        <div v-else-if="!subject" class="schedule-item-details__section">
            <p v-translate class="t-color-blue-light">
                Er zijn geen onderwijsniveaus, evaluaties en/of inzichten voor
                deze les.
            </p>
        </div>

        <div v-if="purpose" class="schedule-item-details__section">
            <div class="schedule-item-purpose">
                <AppIcon
                    name="target"
                    variant="small"
                    class="schedule-item-purpose__icon"
                />

                <span class="schedule-item-purpose__text">{{ purpose }}</span>
            </div>
        </div>

        <div v-if="notes" class="schedule-item-details__section">
            <AppContent
                class="u-margin-bottom"
                size="small"
                variant="background rounded"
            >
                <AppHeader size="xsmall">
                    <AppTitle v-translate as="h3" size="xxsmall">
                        Notitie
                    </AppTitle>
                </AppHeader>

                <p class="t-size-small">
                    {{ notes }}
                </p>
            </AppContent>
        </div>

        <div v-if="subject" class="schedule-item-details__section">
            <AppRow>
                <AppColumn class="column-full--lte-tablet-portrait">
                    <section class="u-margin-bottom-medium">
                        <AppHeader variant="bordered" size="small">
                            <h2 v-translate class="subtitle">
                                Evaluatie vorige les
                            </h2>
                        </AppHeader>
                        <template v-if="previousEvaluations.length > 0">
                            <!-- eslint-disable-next-line -->
                            <div v-for="(previousEvaluation, i) in previousEvaluations" :key="i" class="schedule-item-evaluation">
                                <!-- eslint-disable-next-line -->
                                <p class="t-pre-wrap">{{ previousEvaluation }}</p>
                            </div>
                        </template>
                        <p v-else v-translate class="t-color-blue-light">
                            Er zijn geen eerdere evaluaties gevonden voor deze
                            les.
                        </p>
                    </section>

                    <section>
                        <AppHeader variant="bordered">
                            <h2 v-translate class="subtitle">
                                Evaluatie deze les
                            </h2>
                        </AppHeader>

                        <AppSingleTextareaForm
                            v-model="formValues.evaluation"
                            :disabled="!onLine"
                            field="evaluation"
                            :form-mutation="evaluationMutation"
                            :hint="
                                $gettextInterpolate(
                                    'Vul de evaluatie van de les in, dit wordt bij de volgende %{subject} les weer getoond.',
                                    {
                                        subject: subject.name.toLowerCase()
                                    }
                                )
                            "
                            :label="$gettext('Evaluatie')"
                            persistent-hint
                            @formsuccess="
                                showMessage({
                                    message: $gettext(
                                        'Evaluatie is succesvol opgeslagen.'
                                    ),
                                    type: 'success'
                                })
                            "
                        />
                    </section>
                </AppColumn>
                <AppColumn class="column-full--lte-tablet-portrait">
                    <AppHeader size="small">
                        <h2 v-translate class="subtitle">
                            Vakgerelateerde inzichten
                        </h2>
                    </AppHeader>

                    <AppInsightsWrapper variant="bordered no-border-bottom">
                        <AppLoader v-if="loading" />
                        <template v-else-if="allInsightsGroupedByBulkId.length">
                            <AppInsight
                                v-for="insight in filteredInsights"
                                :key="insight.id"
                                context="schedule"
                                :group-id="groupId"
                                hide-footer
                                :insight="insight"
                            />

                            <AppButton
                                v-if="showInsightToggle"
                                variant="link link-underline link-large"
                                @click="showAllInsights = !showAllInsights"
                            >
                                <!-- eslint-disable-next-line -->
                                {{ showAllInsights ? $gettext("Bekijk minder inzichten") : $gettextInterpolate( "Bekijk alle %{ amount } vakgerelateerde inzichten", { amount: allInsightsGroupedByBulkId.length } ) }}
                            </AppButton>
                        </template>
                        <p
                            v-else-if="!loading"
                            v-translate
                            class="t-color-blue-light"
                        >
                            Er zijn geen inzichten voor dit vak.
                        </p>
                    </AppInsightsWrapper>
                </AppColumn>
            </AppRow>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppButton from "@/components/AppButton.vue";
import AppColumn from "@/components/AppColumn.vue";
import AppContent from "@/components/AppContent.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppInsight from "@/components/AppInsight.vue";
import AppInsightsWrapper from "@/components/AppInsightsWrapper.vue";
import AppLoader from "@/components/AppLoader.vue";
import AppRow from "@/components/AppRow.vue";
import AppScheduleItemEducationalLevels from "@/components/AppScheduleItemEducationalLevels.vue";
import AppSingleTextareaForm from "@/components/AppSingleTextareaForm.vue";
import AppTitle from "@/components/AppTitle.vue";
import GroupedInsights from "@/mixins/GroupedInsights";
import { cloneDeep } from "lodash";
import { consoleError } from "@/console";
import { defineComponent } from "vue";
import gql from "graphql-tag";

export default defineComponent({
    name: "AppScheduleItemDetails",
    components: {
        AppButton,
        AppLoader,
        AppTitle,
        AppSingleTextareaForm,
        AppInsight,
        AppColumn,
        AppRow,
        AppInsightsWrapper,
        AppScheduleItemEducationalLevels,
        AppHeader,
        AppContent,
        AppIcon
    },
    mixins: [GroupedInsights],
    props: {
        id: {
            type: String,
            default: undefined
        },
        groupId: {
            type: String,
            required: true
        },
        grade: {
            type: Number,
            default: undefined
        },
        purpose: {
            type: String,
            default: ""
        },
        notes: {
            type: String,
            default: ""
        },
        evaluation: {
            type: String,
            default: ""
        },
        subject: {
            type: Object,
            default: () => {}
        },
        fullColumns: Boolean,
        isLowerGrade: Boolean
    },
    data() {
        return {
            allEducationalLevels: {},
            allInsights: [],
            formValues: {
                evaluation: this.evaluation
            },
            loading: 0,
            educationalLevelsLoading: 0,
            previousEvaluations: [],
            showAllInsights: false
        };
    },
    computed: {
        ...mapGetters("online", ["onLine"]),
        educationalLevelsLength() {
            return Object.values(this.allEducationalLevels).length;
        },
        filteredInsights() {
            if (
                this.showAllInsights ||
                this.allInsightsGroupedByBulkId.length <= 3
            ) {
                return this.allInsightsGroupedByBulkId;
            }

            return this.allInsightsGroupedByBulkId.slice(0, 2);
        },
        showInsightToggle() {
            return (
                (this.allInsightsGroupedByBulkId.length !==
                    this.filteredInsights.length &&
                    !this.showAllInsights) ||
                (this.showAllInsights &&
                    this.allInsightsGroupedByBulkId.length ===
                        this.filteredInsights.length)
            );
        }
    },
    apollo: {
        allEducationalLevels: {
            query: gql`
                query allEducationalLevelsAppScheduleItem(
                    $groupId: ID!
                    $subjectId: ID!
                    $grade: Int
                ) {
                    allEducationalLevels(
                        groupId: $groupId
                        subjectId: $subjectId
                        grade: $grade
                    ) {
                        edges {
                            node {
                                id
                                level
                                student {
                                    id
                                    fullName
                                    lastName
                                }
                                grade {
                                    id
                                    grade
                                }
                            }
                        }
                    }
                }
            `,
            loadingKey: "educationalLevelsLoading",
            variables() {
                return {
                    groupId: this.groupId,
                    subjectId: this.subject.id,
                    grade: this.grade
                };
            },
            update(data) {
                if (data.allEducationalLevels) {
                    return data.allEducationalLevels.edges
                        .map(x => x.node)
                        .sort((a, b) => {
                            const nameA = a.student.lastName.toUpperCase();
                            const nameB = b.student.lastName.toUpperCase();
                            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
                        })
                        .reduce((acc, obj) => {
                            if (
                                !Object.prototype.hasOwnProperty.call(
                                    acc,
                                    obj.grade.grade
                                )
                            ) {
                                acc[obj.grade.grade] = { 1: [], 2: [], 3: [] };
                            }
                            acc[obj.grade.grade][
                                Math.ceil((obj.level / 6) * 3)
                            ].push(obj);
                            return acc;
                        }, {});
                } else {
                    return this.allEducationalLevels;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van onderwijsniveaus."
                    ),
                    type: "error"
                });
            },
            skip() {
                return !this.subject || !this.subject.parent;
            }
        },
        allInsights: {
            query: gql`
                query allInsightsAppScheduleItem(
                    $groupId: ID!
                    $subjectId: ID!
                    $grade: Int
                ) {
                    allInsights(
                        groupId: $groupId
                        subjectId: $subjectId
                        grade: $grade
                        subjectIncludeChildren: true
                        notExpired: true
                        noCopies: true
                        insightType: [
                            "appointment"
                            "concern"
                            "contact_moment"
                            "diagnosis"
                            "event"
                            "education_plan"
                        ]
                    ) {
                        edges {
                            node {
                                ... on Insight {
                                    id
                                    insightId
                                    type
                                    typeDisplay
                                    title
                                    insight
                                    goal
                                    method
                                    date
                                    important
                                    attachment {
                                        filename
                                        url
                                    }
                                    bulkId
                                    encouraging
                                    dateSpecific
                                    dateWeekdays
                                    educationalLevelFrom
                                    educationalLevelTo
                                    educationalLevelSubject {
                                        id
                                        name
                                    }
                                    educationalLevelGrade {
                                        grade
                                    }
                                    student {
                                        id
                                        fullName
                                        profilePicture {
                                            filename
                                            url(
                                                options: {
                                                    geometry: "100x100"
                                                    asynchronous: true
                                                    crop: "center"
                                                }
                                            )
                                        }
                                    }
                                    subjects {
                                        edges {
                                            node {
                                                id
                                                name
                                            }
                                        }
                                    }
                                    created
                                    modified
                                    expired
                                    goal
                                    method
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    groupId: this.groupId,
                    subjectId: this.subject.id,
                    grade: this.grade
                };
            },
            update(data) {
                if (data.allInsights) {
                    return data.allInsights.edges.map(x => {
                        const node = cloneDeep(x.node);
                        node.type = node.type.toLowerCase();
                        node.subjects = node.subjects.edges.map(x => x.node);
                        return node;
                    });
                } else {
                    return this.allInsights;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van inzichten."
                    ),
                    type: "error"
                });
            },
            skip() {
                return !this.subject;
            }
        },
        previousEvaluations: {
            query: gql`
                query previousEvaluationsAppScheduleItem($scheduleRowId: ID!) {
                    previousEvaluations(scheduleRowId: $scheduleRowId)
                }
            `,
            variables() {
                return {
                    scheduleRowId: this.id
                };
            },
            update(data) {
                if (data.previousEvaluations) {
                    return data.previousEvaluations;
                } else {
                    return this.previousEvaluations;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van evaluaties."
                    ),
                    type: "error"
                });
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        evaluationMutation(formValues) {
            const input = {
                id: atob(this.id).split(":")[1],
                evaluation: formValues.evaluation
            };
            return {
                mutation: gql`
                    mutation scheduleRowAppScheduleItem(
                        $input: ScheduleRowMutationInput!
                    ) {
                        scheduleRow(input: $input) {
                            scheduleRow {
                                id
                                evaluation
                            }
                            errors {
                                field
                                messages
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                }
            };
        },
        refetchInsights() {
            this.$apollo.queries.allInsights.refetch();
        }
    }
});
</script>

<style lang="sass">
.schedule-item-details-wrapper
    padding-top: 24px

    border-top: 1px solid $color-blue-lighter

.schedule-item-details__section + .schedule-item-details__section
    margin-top: 24px
    padding-top: 24px

    border-top: 1px solid $color-blue-lighter
</style>
