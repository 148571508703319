<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppScheduleItemWrapper",
    setup() {
        const { antlerClass } = antlerComponent("schedule-item-wrapper");

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.schedule-item-wrapper
    display: flex
    width: 100%

.schedule-item-content
    padding: $spacing-small $spacing-regular
</style>
