<template>
    <div
        class="schedule-group"
        :style="
            `grid-template-columns: repeat(${scheduleGrades.length}, minmax(calc(50% - 6px), 1fr))`
        "
    >
        <template
            v-if="index === 0 && scheduleGrades && scheduleGrades.length > 1"
        >
            <AppScheduleGroupColumn
                v-for="(grade, gradeIndex) in scheduleGrades"
                :key="`schedule-group-column-grade-${grade}`"
                :style="`grid-column: ${gradeIndex + 1}`"
            >
                <header>
                    <translate
                        tag="h3"
                        class="subtitle-small"
                        :translate-params="{ grade }"
                    >
                        Groep %{grade}
                    </translate>
                </header>
            </AppScheduleGroupColumn>
        </template>
        <template v-if="scheduleGroup">
            <template v-for="(scheduleRows, column) in scheduleGroup">
                <AppScheduleGroupColumn
                    v-if="scheduleRows.length"
                    :key="`schedule-group-column-${index}-${column}`"
                    :style="`grid-column: ${column}`"
                >
                    <AppScheduleGroupItem
                        v-for="(scheduleRow, i) in scheduleRows"
                        :key="scheduleRow.id"
                    >
                        <AppScheduleItem
                            :index="i"
                            :grade="scheduleRow.grade"
                            :group-id="groupId"
                            :schedule-row="scheduleRow"
                            :full-columns="scheduleGrades.length > 1"
                        />
                    </AppScheduleGroupItem>
                </AppScheduleGroupColumn>
                <AppScheduleGroupColumn
                    v-else-if="
                        index === 0 &&
                            columnsMissingScheduleRows &&
                            columnsMissingScheduleRows.includes(
                                parseInt(column)
                            )
                    "
                    :key="`schedule-group-column-empty-${index}-${column}`"
                    :style="`grid-column: ${column}`"
                >
                    <AppMessage
                        class="u-margin-bottom-large"
                        variant="rounded info"
                    >
                        <translate>De planning is nog niet compleet.</translate>
                        <template #options>
                            <AppLink
                                variant="white small"
                                :to="{
                                    name: 'group_schedule_update',
                                    params: {
                                        groupScheduleId: groupSchedule.id
                                    }
                                }"
                            >
                                <AppIcon
                                    name="edit"
                                    variant="small"
                                    class="u-margin-right-small"
                                />
                                <translate>
                                    Planning compleet maken
                                </translate>
                            </AppLink>
                        </template>
                    </AppMessage>
                </AppScheduleGroupColumn>
            </template>
        </template>
        <slot v-else />
    </div>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import AppLink from "@/components/AppLink";
import AppMessage from "@/components/AppMessage";
import AppScheduleGroupColumn from "@/components/AppScheduleGroupColumn";
import AppScheduleGroupItem from "@/components/AppScheduleGroupItem";
import AppScheduleItem from "@/components/AppScheduleItem";
export default {
    name: "AppScheduleGroup",
    components: {
        AppLink,
        AppScheduleGroupItem,
        AppScheduleGroupColumn,
        AppMessage,
        AppIcon,
        AppScheduleItem
    },
    props: {
        columnsMissingScheduleRows: {
            type: Array,
            default: () => []
        },
        groupId: {
            type: String,
            default: ""
        },
        groupSchedule: {
            type: Object, // TODO: create prop validator for object props
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        },
        scheduleGroup: {
            type: Object,
            default: () => {}
        },
        scheduleGrades: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style lang="sass">
.schedule-group
    display: grid
    grid-column: 1 / -1
    grid-gap: $spacing-small

    .schedule-group__item + .schedule-group__item
        margin-top: $spacing-small
</style>
