<template>
    <AppForm
        ref="form"
        class="u-margin-bottom"
        lazy-validation
        @submit.prevent="formSubmit"
    >
        <label class="label">
            <AppHeader v-if="header">
                <AppTitle as="h2" class="subtitle title--centered">
                    <AppIcon
                        v-if="icon"
                        variant="tiny"
                        class="u-margin-right-small"
                        :name="icon"
                    />
                    <span>{{ header }}</span>
                </AppTitle>
            </AppHeader>

            <AppInputTextarea
                class="form-field--textarea-large--tablet-portrait"
                :counter="counter"
                :disabled="loading > 0 || disabled"
                :error-messages="formErrors[field]"
                :hint="hint"
                :label="label"
                :persistent-hint="persistentHint"
                :size="size"
                submit
                :submit-text="submitText"
                :value="formValues[field]"
                @input="$emit('input', $event)"
            />
        </label>
    </AppForm>
</template>

<script>
import AppForm from "@/components/AppForm";
import AppHeader from "@/components/AppHeader.vue";
import AppIcon from "@/components/AppIcon";
import AppInputTextarea from "@/components/AppInputTextarea";
import AppTitle from "@/components/AppTitle.vue";
import FormSave from "@/mixins/FormSave";

export default {
    name: "AppSingleTextareaForm",
    components: { AppTitle, AppHeader, AppInputTextarea, AppIcon, AppForm },
    mixins: [FormSave],
    props: {
        disabled: Boolean,
        field: {
            type: String,
            required: true
        },
        formMutation: {
            type: Function,
            required: true
        },
        header: {
            type: String,
            default: undefined
        },
        icon: {
            type: String,
            default: undefined
        },
        counter: {
            type: Number,
            default: undefined
        },
        hint: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: undefined
        },
        persistentHint: Boolean,
        size: {
            type: String,
            default: undefined
        },
        submitText: {
            type: String,
            default: undefined
        },
        value: {
            type: [String, Number],
            default: ""
        }
    },
    data() {
        return {
            loading: 0,
            formValues: {},
            emptyFormErrors: {}
        };
    },
    watch: {
        field() {
            this.setFormData();
        },
        value() {
            this.formValues[this.field] = this.value;
        }
    },
    mounted() {
        this.setFormData();
    },
    methods: {
        setFormData() {
            this.formValues = { [this.field]: this.value };
            this.emptyFormErrors = { [this.field]: [] };
        },
        getMutation() {
            return this.formMutation(this.formValues);
        },
        formSuccess() {
            this.$emit("formsuccess");
        }
    }
};
</script>
