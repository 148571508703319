<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppInsightsWrapper",
    props: {
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("insights-wrapper", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.insights-wrapper
    position: relative

// variants
.insights-wrapper--bordered
    padding-top: 16px

    border-top:
        width: 1px
        style: solid
        color: $color-blue-lighter

    .insight-wrapper
        padding-bottom: 16px
        margin-bottom: 16px

        border-bottom:
            width: 1px
            style: solid
            color: $color-blue-lighter

        &:last-child
            margin-bottom: 0

.insights-wrapper--no-border-bottom
    .insight-wrapper:last-child
        padding-bottom: 0

        border-bottom: 0
</style>
