<template>
    <div class="schedule-group__item">
        <slot />
    </div>
</template>

<script>
export default {
    name: "AppScheduleGroupItem"
};
</script>
