<template>
    <div class="schedule-group-wrapper">
        <slot />
    </div>
</template>

<script>
export default {
    name: "AppScheduleGroupWrapper"
};
</script>

<style lang="sass">
.schedule-group-wrapper
    display: grid
    grid-gap: $spacing-small
    margin-bottom: $spacing-regular
    padding-bottom: $spacing-regular

    overflow: auto
</style>
