export default {
    data() {
        return {
            allInsights: []
        };
    },
    computed: {
        allInsightsGroupedByBulkId() {
            if (!Array.isArray(this.allInsights)) return [];

            const bulkIdInsights = {};

            return this.allInsights.reduce((acc, insight) => {
                insight.students = [
                    {
                        id: insight.student.id,
                        fullName: insight.student.fullName
                    }
                ];

                if (insight.bulkId) {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            bulkIdInsights,
                            insight.bulkId
                        )
                    ) {
                        bulkIdInsights[insight.bulkId].students.push({
                            id: insight.student.id,
                            fullName: insight.student.fullName
                        });
                        bulkIdInsights[insight.bulkId].students.sort((a, b) => {
                            const nameA = a.fullName.toLowerCase();
                            const nameB = b.fullName.toLowerCase();
                            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
                        });
                        return acc;
                    } else {
                        bulkIdInsights[insight.bulkId] = insight;
                    }
                }

                acc.push(insight);

                return acc;
            }, []);
        }
    }
};
