<template>
    <AppRow variant="gutters-small">
        <AppColumn
            v-for="(levels, i) in gradeLevels"
            :key="`column${i}`"
            class="column-3"
        >
            <div class="schedule-item-level">
                <div class="schedule-item-level-stars u-margin-bottom-small">
                    <template v-if="!lowerGrade">
                        <AppIcon
                            v-for="j in 3"
                            :key="`star${i}${j}`"
                            name="star"
                            :class="{
                                'icon--star--active': j <= i
                            }"
                        />
                    </template>
                    <template v-else>
                        <div class="d-flex">
                            <AppIcon
                                :key="`lower-grade${i}1`"
                                name="minus-small"
                                variant="circle"
                                :class="{
                                    'icon--circle-blue': '1' === i
                                }"
                            />
                            <AppIcon
                                :key="`lower-grade${i}2`"
                                name="check"
                                variant="circle"
                                :class="{
                                    'icon--circle-green-dark': '2' === i
                                }"
                            />
                            <AppIcon
                                :key="`lower-grade${i}3`"
                                name="star"
                                variant="circle"
                                :class="{
                                    'icon--circle-purple': '3' === i
                                }"
                            />
                        </div>
                    </template>
                </div>

                <div class="schedule-item-level-list">
                    <ul class="list schedule-item-level__list">
                        <li
                            v-for="level in levels"
                            :key="level.id"
                            class="schedule-item-level__list-item"
                        >
                            <router-link
                                class="schedule-item-level__link"
                                :to="{
                                    name: 'student_level',
                                    params: {
                                        id: level.student.id
                                    }
                                }"
                            >
                                {{ level.student.fullName }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </AppColumn>
    </AppRow>
</template>

<script>
import AppColumn from "@/components/AppColumn.vue";
import AppIcon from "@/components/AppIcon";
import AppRow from "@/components/AppRow.vue";

export default {
    name: "AppScheduleItemEducationalLevels",
    components: {
        AppColumn,
        AppRow,
        AppIcon
    },
    props: {
        gradeLevels: {
            type: Object,
            default: () => ({ 1: [], 2: [], 3: [] })
        },
        lowerGrade: Boolean
    }
};
</script>
