var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"schedule-group",style:(("grid-template-columns: repeat(" + (_vm.scheduleGrades.length) + ", minmax(calc(50% - 6px), 1fr))"))},[(_vm.index === 0 && _vm.scheduleGrades && _vm.scheduleGrades.length > 1)?_vm._l((_vm.scheduleGrades),function(grade,gradeIndex){return _c('AppScheduleGroupColumn',{key:("schedule-group-column-grade-" + grade),style:(("grid-column: " + (gradeIndex + 1)))},[_c('header',[_c('translate',{staticClass:"subtitle-small",attrs:{"tag":"h3","translate-params":{ grade: grade }}},[_vm._v(" Groep %{grade} ")])],1)])}):_vm._e(),(_vm.scheduleGroup)?[_vm._l((_vm.scheduleGroup),function(scheduleRows,column){return [(scheduleRows.length)?_c('AppScheduleGroupColumn',{key:("schedule-group-column-" + _vm.index + "-" + column),style:(("grid-column: " + column))},_vm._l((scheduleRows),function(scheduleRow,i){return _c('AppScheduleGroupItem',{key:scheduleRow.id},[_c('AppScheduleItem',{attrs:{"index":i,"grade":scheduleRow.grade,"group-id":_vm.groupId,"schedule-row":scheduleRow,"full-columns":_vm.scheduleGrades.length > 1}})],1)}),1):(
                    _vm.index === 0 &&
                        _vm.columnsMissingScheduleRows &&
                        _vm.columnsMissingScheduleRows.includes(
                            parseInt(column)
                        )
                )?_c('AppScheduleGroupColumn',{key:("schedule-group-column-empty-" + _vm.index + "-" + column),style:(("grid-column: " + column))},[_c('AppMessage',{staticClass:"u-margin-bottom-large",attrs:{"variant":"rounded info"},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('AppLink',{attrs:{"variant":"white small","to":{
                                name: 'group_schedule_update',
                                params: {
                                    groupScheduleId: _vm.groupSchedule.id
                                }
                            }}},[_c('AppIcon',{staticClass:"u-margin-right-small",attrs:{"name":"edit","variant":"small"}}),_c('translate',[_vm._v(" Planning compleet maken ")])],1)]},proxy:true}],null,true)},[_c('translate',[_vm._v("De planning is nog niet compleet.")])],1)],1):_vm._e()]})]:_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }