<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppScheduleItemHolder",
    setup() {
        const { antlerClass } = antlerComponent("schedule-item");

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.schedule-item
    border-radius: $global-border-radius-big

    background-color: #fff
    box-shadow: 1px 1px 2px rgba(#000, .16)

    transition: transform 500ms

.schedule-item--clickable
    cursor: pointer
    transition: $global-transition-fast

    &:hover .schedule-item-info
        color: $color-secondary

.schedule-item--break
    background-color: rgba(#fff, .5)

    .schedule-item-content
        padding:
            top: $spacing-xsmall
            bottom: $spacing-xsmall

.schedule-item--active
    .schedule-item-info
        color: $color-secondary

.schedule-item:not(:last-child)
    margin-bottom: $spacing-small

// info

.schedule-item-info
    font:
        weight: $strong-weight
        family: $primary-font-family

    +mq-desktop
        display: flex
        align-self: center

.schedule-item__info
    padding:
        top: $spacing-xsmall
        bottom: $spacing-xsmall

    @include font-size(14px)

    +mq-desktop
        display: flex
        align-items: center

.schedule-item__info-divider
    margin:
        right: $spacing-xsmall
        left: $spacing-xsmall

    +mq-desktop
        display: none

.schedule-item__info:not(:last-child)
    padding-right: $spacing-regular
    margin-right: $spacing-regular

    +mq-desktop
        border-right:
            width: 1px
            color: $color-blue-lighter
            style: solid

.schedule-item__info--date
    flex-direction: column

.schedule-item__info--sub-subject
    @include font-size(14px)

    +mq-lte-mobile
        flex-basis: 100%
        width: 100%

.schedule-item__info-text
    line-height: 1.2

    // grouped for truncation, carefully ignoring recess
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden

// options

.schedule-item-options
    display: flex
    flex-direction: column
    margin-left: auto

.schedule-item-options__delete
    margin-top: auto
    flex-grow: 0

.schedule-item-options__toggle
    margin:
        top: auto
        bottom: auto

// details

.schedule-item-details
    padding:
        right: $spacing-regular
        bottom: $spacing-regular
        left: $spacing-regular

.schedule-item-level__list-item
    @include font-size(14px, 1)

// forms

.schedule-item-evaluation
    margin-bottom: $spacing-small

    & + &
        padding-top: $spacing-small

        border-top:
            width: 1px
            color: $color-blue-lighter
            style: solid

.schedule-item--form
    .schedule-item-content
        padding: $spacing-regular $spacing-small

// purpose

.schedule-item-purpose
    display: flex
    flex: 1
    align-items: center
    padding: $spacing-xsmall $spacing-small

    @include font-size(13px)
    color: #fff
    font-weight: bold

    background-color: $color-blue-shade
    border-radius: $global-border-radius-rounded

.schedule-item-purpose__icon
    margin-right: $spacing-small

.form-group--inline
    .form-field.schedule-item-time
        +mq-lte-tablet-portrait
            flex: 1

    .form-field.schedule-item-subject
        +mq-lte-tablet-portrait
            flex: 1

        +mq-lte-tablet
            flex-basis: 100%
            margin:
                top: $spacing-small
                left: 0

    .form-field.schedule-item-subject-description
        +mq-lte-tablet-portrait
            flex: 0 0 100%
            margin:
                top: $spacing-small
                left: 0

.schedule-item.sortable-ghost
    opacity: .5
    background-color: rgba($color-blue, .3)
    box-shadow: none

    .schedule-item-form,
    .schedule-item-options
        opacity: 0
</style>
