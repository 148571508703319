<template>
    <AppScheduleItemHolder
        :class="[
            { 'schedule-item--break': scheduleRow.isBreak },
            { 'schedule-item--active': showDetails }
        ]"
    >
        <AppScheduleItemWrapper
            :class="[
                'schedule-item-content',
                {
                    'schedule-item--clickable':
                        !scheduleRow.isBreak && hasDetails
                }
            ]"
            @click.native="
                showDetails = !showDetails && !scheduleRow.isBreak && hasDetails
            "
        >
            <div class="schedule-item-info">
                <template v-if="scheduleRow.isBreak">
                    <div class="schedule-item__info schedule-item__info--date">
                        <span>{{ scheduleRow.startTime }}</span>
                        <span class="schedule-item__info-divider">-</span>
                        <span>{{ scheduleRow.endTime }}</span>
                    </div>
                    <div key="break" v-translate class="schedule-item__info">
                        Pauze
                    </div>
                </template>
                <template v-else>
                    <div class="schedule-item__info schedule-item__info--date">
                        <span>{{ scheduleRow.startTime }}</span>
                        <span class="schedule-item__info-divider">-</span>
                        <span>{{ scheduleRow.endTime }}</span>
                    </div>

                    <div class="schedule-item__info schedule-item__info--text">
                        <div class="schedule-item__info-text">
                            {{ rowName }}
                        </div>
                    </div>
                    <div
                        v-if="scheduleRow.subject && scheduleRow.subject.parent"
                        class="schedule-item__info schedule-item__info--text"
                    >
                        <div class="schedule-item__info-text">
                            {{ scheduleRow.subject.name }}
                        </div>
                    </div>
                    <div
                        v-else-if="scheduleRow.subjectDescription"
                        class="schedule-item__info schedule-item__info--text"
                    >
                        <div class="schedule-item__info-text">
                            {{ scheduleRow.subjectDescription }}
                        </div>
                    </div>
                    <div
                        v-if="scheduleRow.theme"
                        class="schedule-item__info schedule-item__info--text"
                    >
                        <div class="schedule-item__info-text">
                            {{ scheduleRow.theme }}
                        </div>
                    </div>
                </template>
            </div>

            <div
                v-if="!scheduleRow.isBreak && hasDetails"
                class="schedule-item-options"
            >
                <AppButton
                    variant="toggle icon-small"
                    class="schedule-item-options__toggle"
                    :icon="showDetails ? 'minus' : 'plus'"
                />
            </div>
        </AppScheduleItemWrapper>

        <div v-if="showDetails && hasDetails" class="schedule-item-details">
            <AppTabs
                v-if="scheduleRow.bundleRows"
                class="u-margin-bottom"
                tabs-class="tabs--boxed"
                :parameter="`schedule-${scheduleRow.rawId}`"
                :hide-tabs="scheduleRow.bundleRows.length === 1"
            >
                <!-- eslint-disable -->
                <AppTab
                    v-for="(bundleRow, i) in scheduleRow.bundleRows"
                    :id="bundleRow.rawId"
                    :key="`${index}-tab-${i}`"
                    :name="getTabLabel(bundleRow)"
                    :subName="getTabSubtext(bundleRow)"
                >
                    <AppHeader size="small">
                        <AppTitle as="h2">
                            {{ getTabLabel(bundleRow) }}
                        </AppTitle>
                    </AppHeader>

                    <AppArticle
                        size="small"
                        spacing="small"
                        class="u-margin-bottom-small"
                    >
                        <p v-if="bundleRow.subject && bundleRow.subject.parent">
                            {{ bundleRow.subject.name }}
                        </p>
                        <p v-else-if="bundleRow.subject && bundleRow.subjectDescription">
                            {{ bundleRow.subjectDescription }}
                        </p>
                        <p v-if="bundleRow.theme">
                            {{ bundleRow.theme }}
                        </p>
                    </AppArticle>

                    <AppScheduleItemDetails
                        :id="bundleRow.id"
                        :purpose="bundleRow.purpose"
                        :notes="bundleRow.notes"
                        :subject="bundleRow.subject"
                        :full-columns="fullColumns"
                        :evaluation="bundleRow.evaluation"
                        :is-lower-grade="isLowerGrade"
                        :group-id="groupId"
                        :grade="grade"
                    />
                </AppTab>
            </AppTabs>
            <!--eslint-enable-->

            <AppScheduleItemDetails
                v-else
                :id="scheduleRow.id"
                :purpose="scheduleRow.purpose"
                :notes="scheduleRow.notes"
                :subject="scheduleRow.subject"
                :full-columns="fullColumns"
                :evaluation="scheduleRow.evaluation"
                :is-lower-grade="isLowerGrade"
                :group-id="groupId"
                :grade="grade"
            />
        </div>
    </AppScheduleItemHolder>
</template>

<script>
import AppArticle from "@/components/AppArticle.vue";
import AppButton from "@/components/AppButton";
import AppHeader from "@/components/AppHeader";
import AppScheduleItemDetails from "@/components/AppScheduleItemDetails.vue";
import AppScheduleItemHolder from "@/components/AppScheduleItemHolder.vue";
import AppScheduleItemWrapper from "./AppScheduleItemWrapper.vue";
import AppTab from "@/components/AppTab";
import AppTabs from "@/components/AppTabs";
import AppTitle from "@/components/AppTitle.vue";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
    name: "AppScheduleItem",
    components: {
        AppScheduleItemDetails,
        AppArticle,
        AppTitle,
        AppScheduleItemHolder,
        AppScheduleItemWrapper,
        AppHeader,
        AppTab,
        AppTabs,
        AppButton
    },
    props: {
        index: {
            type: Number,
            default: 0
        },
        grade: {
            type: Number,
            default: undefined
        },
        groupId: {
            type: String,
            required: true
        },
        scheduleRow: {
            type: Object,
            required: true
        },
        fullColumns: Boolean,
        variant: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            showDetails: false
        };
    },
    setup(props) {
        const { antlerClass } = antlerComponent("schedule-item", props);

        return {
            antlerClass
        };
    },
    computed: {
        ...mapGetters("insightmodal", [
            "createdInsights",
            "expiredInsights",
            "deletedInsights"
        ]),
        rowName() {
            if (this.scheduleRow.subject && this.scheduleRow.subject.parent) {
                return this.scheduleRow.subject.parent.name;
            } else if (this.scheduleRow.subject) {
                return this.scheduleRow.subject.name;
            } else if (
                this.scheduleRow.bundleRows &&
                this.scheduleRow.bundleRows.length
            ) {
                if (
                    this.scheduleRow.bundleRows[0].subjectBundle
                        .bundleLabelType === "SUBJECT"
                ) {
                    return this.bundleSubjectsName;
                } else {
                    return this.scheduleRow.bundleRows[0].subjectBundle.name;
                }
            } else {
                return this.scheduleRow.subjectDescription;
            }
        },
        bundleSubjectsName() {
            const set = new Set(
                this.scheduleRow.bundleRows.map(item => {
                    return this.getTabLabel(item);
                })
            );
            const names = Array.from(set);
            return names.join(", ");
        },
        hasDetails() {
            return (
                !!this.scheduleRow.subject ||
                !!this.scheduleRow.purpose ||
                !!this.scheduleRow.bundleRows
            );
        },
        isLowerGrade() {
            return this.grade == null || this.grade <= 2;
        }
    },
    watch: {
        createdInsights() {
            this.refetchInsights();
        },
        expiredInsights() {
            this.refetchInsights();
        },
        deletedInsights() {
            this.refetchInsights();
        }
    },
    methods: {
        getTabSubtext(bundleRow) {
            if (bundleRow.subject) {
                if (bundleRow.subject.parent) {
                    return bundleRow.subject.name;
                } else {
                    return bundleRow.subjectDescription;
                }
            }
            return "";
        },
        getTabLabel(bundleRow) {
            if (bundleRow.subject && bundleRow.subject.parent) {
                return bundleRow.subject.parent.name;
            } else if (bundleRow.subject) {
                return bundleRow.subject.name;
            } else {
                return bundleRow.subjectDescription;
            }
        }
    }
});
</script>
