<template>
    <div class="tabs-wrapper">
        <nav v-if="!hideTabs" :class="['tabs', tabsClass]">
            <AppButton
                v-for="(tab, i) in tabs"
                :key="i"
                :aria-controls="tab.hash"
                :aria-selected="tab.isActive"
                variant="clean"
                :class="['tab', { 'tab--active': tab.isActive }]"
                role="tab"
                @click.prevent="selectTab(tab.hash, $event)"
            >
                <AppIcon
                    v-if="tab.icon"
                    :name="tab.icon"
                    class="u-margin-right-tiny"
                />

                <span class="tab__text">
                    <span v-if="tab.name" class="tab__title">
                        {{ tab.name }}
                    </span>
                    <span v-if="tab.subName" class="tab__subtitle">
                        {{ tab.subName }}
                    </span>
                </span>
            </AppButton>
        </nav>

        <div class="tabs-details">
            <slot />
        </div>
    </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppIcon from "@/components/AppIcon";

export default {
    name: "AppTabs",
    components: { AppButton, AppIcon },
    props: {
        parameter: {
            type: String,
            required: false,
            default: ""
        },
        tabsClass: {
            type: String,
            required: false,
            default: ""
        },
        hideTabs: Boolean
    },
    data() {
        return {
            tabs: [],
            tabParam: "tab"
        };
    },
    mounted() {
        // this filters out the tab-component children
        this.tabs = this.$children.filter(x => x);

        // change tab-parameter if prop is set
        if (this.parameter) {
            this.tabParam = this.parameter;
        }

        // check if route has tab-parameter and select tab based on value
        if (
            Object.prototype.hasOwnProperty.call(
                this.$route.query,
                this.tabParam
            )
        ) {
            this.selectTab(this.$route.query[this.tabParam]);
        } else if (this.tabs.length) {
            this.selectTab(this.tabs[0].hash);
        }
    },
    methods: {
        findTab(hash) {
            return this.tabs.find(tab => tab.hash === hash);
        },
        selectTab(selectedTabHash, event) {
            const selectedTab = this.findTab(selectedTabHash);

            // if tab does not exist, return
            if (!selectedTab) {
                return;
            }

            if (selectedTab.isDisabled && event) {
                event.preventDefault();
                return;
            }

            this.tabs.forEach(tab => {
                tab.isActive = tab.hash === selectedTab.hash;
            });

            this.$emit("changed", { tab: selectedTab });

            // get new route parameters on event
            if (event) {
                // update url
                this.$router.push(
                    {
                        path: this.$route.path,
                        query: {
                            ...this.$route.query,
                            [this.tabParam]: selectedTabHash
                        }
                    },
                    () => {}
                );
            }
        },
        getTabIndex(hash) {
            const tab = this.findTab(hash);
            return this.tabs.indexOf(tab);
        }
    }
};
</script>

<style lang="sass">
.tabs-wrapper
    position: relative

    display: flex
    flex-direction: column
    height: 100%
    width: 100%
    min-width: 0px

.tabs
    display: flex
    flex: 0 0 auto
    gap: $spacing-small
    margin-bottom: 16px

    text-align: center

    border-bottom:
        width: 1px
        style: solid
        color: $color-blue-shade-light

    +mq-desktop
        gap: $spacing-regular

.tabs--full
    flex: 1

    .tab
        flex-grow: 1

.tab
    position: relative

    display: inline-flex
    align-items: center
    justify-content: center
    padding: $spacing-small 8px

    @include font-size(15px)
    color: $color-primary
    font-weight: $strong-weight

    background-color: transparent
    border: 0 none

    +mq-desktop
        padding: $spacing-small

        @include font-size($base-font-size)

.tab--active
    &::after
        position: absolute
        bottom: -3px
        left: 0

        display: block
        height: 5px
        width: 100%

        content: ""
        border-radius: $global-border-radius-rounded
        background-color: $color-primary

.tabs-details
    margin-top: $spacing-regular

.tabs-details,
.tab-content
    display: flex
    flex-direction: column
    flex: 1
    min-width: 0

.tabs--boxed
    flex-direction: row
    flex-wrap: wrap
    padding: 8px
    gap: 8px

    background-color: $color-blue-lighter

    .tab
        padding:
            top: 8px
            bottom: 8px
        flex-grow: 1
        min-width: 200px

        color: $color-blue-shade-dark

        border-radius: 4px

        &:hover
            background-color: $color-blue-lightest
            box-shadow: 1px 1px 2px rgba(0, 0, 0, .16)
            opacity: 1

        &::after
            display: none

    .tab--active,
    .tab--active:hover
        color: $color-blue

        background-color: #fff
        box-shadow: 1px 1px 2px rgba(0, 0, 0, .16)

    .tab__text
        display: flex
        flex-direction: column
        gap: 4px

    .tab__title
        @include font-size(13px, 16px)
        font-family: $primary-font-family
        font-weight: $strong-weight

    .tab__subtitle
        @include font-size(14px, 16px)
        font-weight: normal
        font-family: $secondary-font-family
</style>
