<template>
    <div
        v-show="isActive"
        :aria-hidden="!isActive"
        class="tab-content"
        role="tabpanel"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: "AppTab",
    props: {
        id: {
            type: String,
            default: null
        },
        name: {
            type: String,
            required: true
        },
        subName: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            required: false,
            default: null
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isActive: false
        };
    },
    computed: {
        hash() {
            return this.computedId;
        },
        computedId() {
            return this.id
                ? this.id
                : this.name.toLowerCase().replace(/ /g, "-");
        }
    },
    mounted() {
        this.isActive = this.selected;
    }
};
</script>
